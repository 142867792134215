  html {
    overflow-y: scroll;
  }
  
  body{
    font-family: Nunito;
    background-color: var(--background);
    --background: rgba(0, 0, 0, 0.95);
    color: white;
  }
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    --yellow: #fabc03;
    --red: #ec1f24;  
    --darkredold: #AA0813;
    --darkred: #EA0B1A;
    --button: #AA0813;
    --buttonhover: #db0a189c;
  }
    
  .show-pic, .home-pic, .side-card-pic, .pic, .team-pic {
    animation: fadeInAnimation ease .75s;
  }
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /*----------HTML TAGS----------*/
    h1{
      font-size: 30px;
      font-weight: bold;
      margin: 25px 0;
      color: var(--yellow);
      font-family: Dosis;
      text-transform: uppercase;
    }

    h2{
      font-weight: bold;
      font-size: 25px;
      font-family: "dosis";
      color: var(--yellow);
    }

    h3{
      font-weight: bold;
      font-size: 20px;
      font-family: Dosis;
      color: var(--yellow);
    }

    @media (max-width: 801px){
      .mobile-hide, .overlay-text{
        display: none !important;
      }
    }

  /* ----------- CLASSES -----------*/
    .page{
      padding: 0 15px;
      flex-grow: 1;
      width: 100%;
    }

    .gallery{
      padding: 0;
      flex-grow: 1;
      width: 100%;
    }

    .gallery-header{
      margin-left: 15px;
    }
  
    .about{
      margin-top: 15px;
      margin-bottom: 35px;
    }
  
    .home{
      min-height: 100vh;
      padding: 0;
    }

    .upcoming{
      padding: 0 15px;
    }
    
    .pic{
      max-width: 100%;
      display: block;
      height: auto;
      width: 100%;
    }
    
    .side-card-pic{
      max-width: 100%;
      height: auto;
      border: 1px solid gray;
    }
  
    .home-pic{
      width: 100%;
      height: auto;
      margin: -15px 0 15px 0;
    }
  
    .home-text{
      font-size: 20px;
      text-align: center;
      color: var(--yellow);
      max-width: 100%;
      font-weight: 500;
      padding: 0 20px;
    }
  
    .venues{
      display: none;
    }
  
    .partners{
      display: none;
    }
  
    .red-heading{
      font-weight: bold;
      font-size: 20px;
      font-family: Dosis;
      color: var(--darkred);
    }
    
    .divider{
      background-color: var(--darkred);
      height: 2px;
      width: 100%;
      margin: 30px 0;
    }

    .divider-mobile{
      background-color: var(--darkred);
      height: 2px;
      margin: 30px 15px;
    }
  
    .show-cards{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  
    .team-cards{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  
    .show-pic{
      max-width: 100%;
      height: auto;
      border: 1px solid gray;
    }
  
    .team-pic{
      max-width: 100%;
      height: auto;
      border: 1px solid gray;
    }
  
    .side-card{
      margin-bottom: 125px;
    }
  
    .side-card-body{
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    
    .card-text{
      font-size: 16px;
    }
  
    .presents-body{
      align-items: center;
      margin-bottom: 30px;
    }
    
    .presents-cards{
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      width: 100%;
    }
  
    .presents-main-pic{
      border: none;
      height: auto;
    }
  
    .presents-text{
      text-align: center;
    }
  
    .presents-pic{
      width: calc(50% - 4px);  
      border: 1px solid gray;
      box-sizing: content-box;
      height: auto;
    }
  
    .show-pic{
      width: 100%;
      border: 1px solid gray;
    }

    .date{
      font-size: 15px;
    }
    
    .no-underline{
      text-decoration: none;
    }
  
    .insta-link{
      color: white;
    }

    .team-links{
      padding-left: 4px;
    }
  
    .team-links a{
      color: var(--darkred);
      font-weight: bold;
      font-family: Dosis;
      display: block;
      margin-bottom: 2px;
    }
    
    .contact{
      width: 100%;
      max-width: 500px;
      margin: auto;
      text-align: center;
    }
  
    .contact-body{
      margin-bottom: 35px;
    }

    .press{
      width: 100%;
      max-width: 500px;
      margin: auto;
      text-align: center;
    }

    .press-heading{
      color: var(--darkred)
    }

    .press-entry{
      margin-bottom: 35px;
    }

    .credit-link{
      color: white
    }
    
    .card-body{
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    
    .gray{
      color: #888888;
    }
    
    .socials{
      display: flex;
      justify-content: center;
      gap: 8px;
      margin: 15px 0;
    }
    
    .socials-footer{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
    .icon-big{
      max-width: 50px;
      height: auto;
    }
    
    .icon{
      width: 45px;
      height: auto;
      vertical-align: bottom;
    }
    
    footer{
      background-color: var(--background);
      color: white;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 10px;
      margin-top: 200px;
      justify-content: space-between;
      border-top: 1px solid white;
      width: 100%;
    }
    
    .ext-link{
      display: flex;
      align-items: center;
      gap: 3px
    }

    .center{
      text-align: center;
      margin-bottom: 30px;
    }

    .discount{
      text-align: center;
      margin-bottom: 32px;
      margin-top: 16px;
    }
    
    .to-eventbrite {
      background-color: var(--button);
      font-weight: bold;
      margin-top: 4px;
      color: white;
      padding: 15px 32px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Dosis;
      border-radius: 8px;
      width: 33%;
      }
  
    .eventbrite-big{
      background-color: var(--button);
      font-weight: bold;
      width: 100%;
      margin-top: 15px;
      color: white;
      padding: 15px 32px;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      font-family: Dosis;
      border-radius: 8px;
    }
  
  /* ----------- DESKTOP -----------*/
  @media (min-width: 801px){
    .page{
      max-width: 1600px;
    }

    .gallery{
      max-width: 1600px;
      padding: 0 15px;
      flex-grow: 1;
      width: 100%;
    }

    .gallery-header{
      margin-left: 0;
    }
  
    .desktop-hide{
      display: none !important;
    }

    .partners{
      text-align: center;
      display: block;
    }
  
    .shows{
      max-width: 1200px;
    }

    h1{
        font-size: 40px;
      }
  
    .about{
      margin: 25px 0;
      display: grid;
      /*grid-template-columns: 3fr 2fr;*/
      grid-template-columns: 1fr 600px;
      align-items: center;
    }

    .home-pic{
      margin: 0;
      grid-column: 1 / 2;
      width: 100%;
      mask-image: linear-gradient(to right, transparent 0%, black 1%, black 70%, transparent 100%);
   }
  
    .about-right{
      grid-column: 2 / 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .home-text{
      font-size: 25px;
      display: flex;
      flex-direction: column;
      padding: 0 30px;
      line-height: 1.2;
    }
  
    .venues{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-evenly;
      align-items: center;
    }

    .show-pic{
      transition: all .25s ease;
    }
  
    .show-pic:hover{
      opacity: .7;
    }
  
    .image-overlay{
      position: relative;
    }
  
    .fade, .overlay-text{
      transition: all .25s ease;
    }
  
    .image-overlay:hover .fade{
      opacity: .8;
    }
  
    .image-overlay:hover .overlay-text{
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  
    .overlay-text {
      color: white;
      font-size: 18px;
      position: absolute;
      padding: 14px 0 14px 14px;
      border-bottom: 1px solid gray;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: var(--background);
      opacity: 0;
      margin: 0 1px;
    }
    
    .divider{
      margin: 15px 0;
    }
  
    .team-cards{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      grid-auto-rows: max-content;
      gap: 30px
    }
  
    .presents-cards{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      grid-auto-rows: max-content;
      gap: 15px;
      margin-top: 60px;
    }
  
    /* .gallery-pics{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      grid-auto-rows: max-content;
      gap: 15px;
    } */

    .side-cards{
      display: flex;
      flex-direction: column;
    }

    .flip{
      flex-direction: row-reverse;
      
    }
  
    .side-card{
      display: flex;
      gap: 30px;
      align-items: flex-start;
      text-align: justify;
    }
  
    .side-card-pic{
      max-width: 350px;
      margin-top: 6px;
      align-self: center;
    }
  
    .side-card-body{
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0;
    }
  
    .card-text{
      font-size: 20px;    
    }
    
    .show-cards{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      grid-auto-rows: max-content;
      column-gap: 30px;
      row-gap: 75px;
    }
  
    .show-card{
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  
    .card-body{
      line-height: 1.2;
      flex-grow: 1;
    }

    .center{
      font-size: 20px;
      margin: 0 200px;
      margin-bottom: 30px;

    }
  
    .presents{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .presents-main-pic{
      max-width: 600px;
    }
  
    .presents-pic{
      width: auto;
      max-width: 100%;
      box-sizing: border-box;
    }

    .contact-body{
      margin-bottom: 25px;
    }
    
    .socials{
      margin: 0;
    }
    
    .socials-footer{
      gap: 8px;
    }
    
    .icon-big{
      max-width: 75px;
      transition: all .25s ease;
    }
  
    .icon-big:hover{
      opacity: .7;
    }
    
    .icon{
      transition: all .25s ease;
    }
  
    .icon:hover{
      opacity: .7;
    }
    
    .to-eventbrite {
      margin-top: auto;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      transition: all .25s ease;
    }
  
    .to-eventbrite:hover{
      background-color: var(--buttonhover);
    }
  
    .eventbrite-big{
      width: auto;
      margin-bottom: 25px;
      margin-top: 0;
      cursor: pointer;
      transition: all .25s ease;
      align-self: center;
    }
  
    .eventbrite-big:hover{
      background-color: var(--buttonhover);
    }
  }

      /* ----------- HOME-PIC -----------*/
      @media (min-width: 801px) and (max-width: 1350px){
        .about{
          margin-top: 25px;
          display: grid;
          grid-template-columns: 3fr 2fr;
          grid-template-rows: auto;
          align-items: center;
        }
      
        .home-pic{
          grid-column: 1 / 2;
          width: 100%;
          height: auto;
          margin: 0;
        }
      
        .divider.grid, .partners, .venues{
          grid-column: span 2;
        }
      
        .divider.grid{
          margin: 50px 0;
        }
      
        .venues{
          margin: 0 150px 25px 150px;
        }
      
        .partners{
          margin-bottom: 20px;
        }
      
        .about-right{
          display: contents !important;
        }
      
        .home-text{
          padding: 0 5px !important;
          gap: 10px;
        }
      }